<template>
  <section class="l-content">
    <div class="flex align-center" style="height: 100%">
      <el-card style="height: 100%; width: 300px">
        <el-tree
          :data="categoryTree"
          ref="webMenuTree"
          node-key="categoryCode"
          highlight-current
          :expand-on-click-node="false"
          default-expand-all
          :props="{ label: 'categoryName' }"
          @node-click="nodeClickHandle"
        >
        </el-tree>
      </el-card>
      <el-card class="flex-sub margin-left-mini" style="height: 100%">
        <l-table :option="tableOption" :data="pageData.list" :start-index="pageData.startIndex"> </l-table>
        <l-pager :page="pageData" @change="getPageData"></l-pager>
      </el-card>
    </div>
  </section>
</template>

<script>
import { mapState } from 'vuex'
import Addoredit from './category/_addoredit'
export default {
  computed: {
    ...mapState(['userInfo', 'dictData'])
  },
  name: 'AssetList',
  data() {
    return {
      tableOption: {
        stripe: true,
        columns: [
          { label: '序号', type: 'index' },
          { label: '分类编号', prop: 'categoryCode' },
          { label: '分类名称', prop: 'categoryName' },
          { label: '上级分类', prop: 'parentName', filter: (a) => a || '-' },
          { label: '排序', prop: 'sort' },
          { label: '备注', prop: 'remark', overflow: true }
        ],
        operation: {
          width: '250',
          header: { type: 'warning', icon: 'el-icon-plus', label: '新增分类', click: this.add },
          buttons: [
            { type: 'primary', icon: 'el-icon-edit', label: '编辑', click: this.edit },
            { type: 'danger', icon: 'el-icon-remove', label: '删除', click: this.del }
          ]
        }
      },
      categoryTree: null,
      selCategory: null,
      condition: {
        // enterpriseCode: this.userInfo.enterpriseCode,
        keyword: '',
        preCode: '',
        pageIndex: 1,
        pageSize: 20
      },
      pageData: null
    }
  },
  created() {
    this.getCategoryTree()
    this.getPageData()
  },
  methods: {
    getCategoryTree() {
      this.$lgh.get('api/enterprise/easset/category/tree', ['enterpriseCode'], false).then((res) => {
        this.categoryTree = res
      })
    },
    async getPageData(pageOption) {
      Object.assign(this.condition, pageOption)
      this.pageData = await this.$lgh.get('api/enterprise/easset/category/pagelist', ['enterpriseCode', this.condition])
    },
    nodeClickHandle(data) {
      this.condition.preCode = data.categoryCode
      this.getPageData()
    },
    async add() {
      await this.$lgh.openDialog({
        ins: Addoredit,
        title: '新增分类',
        data: {
          dataInfo: Object.assign(
            {},
            {
              enterpriseCode: this.userInfo.enterpriseCode,
              sort: 1,
              parentCodeArr: ['']
            }
          )
        }
      })
      this.$lgh.toastSuccess('新建资产信息成功')

      this.getCategoryTree()
      this.getPageData()
    },
    async edit(item) {
      await this.$lgh.openDialog({
        ins: Addoredit,
        title: '修改资产信息',
        data: {
          dataInfo: Object.assign({}, item)
        }
      })
      this.$lgh.toastSuccess('修改资产信息成功')
      this.getCategoryTree()
      this.getPageData()
    },
    del(item) {
      this.$lgh.msgConfirm('确认删除该分类信息?', async () => {
        await this.$lgh.get('api/enterprise/easset/category/delete', {
          categoryId: item.categoryId
        })
        this.$lgh.notifySuccess('删除成功', item.categoryName + '信息已删除')
        this.getCategoryTree()
        this.getPageData()
      })
    }
  }
}
</script>
