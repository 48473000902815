<template>
  <el-dialog v-if="dataInfo" :title="title" @closed="_closed" :visible.sync="visible" :close-on-click-modal="false" :close-on-press-escape="false" custom-class="top5" top="5vh">
    <el-form ref="dataInfo" :model="dataInfo" :rules="dataRules" label-width="120px">
      <!-- <el-divider class="margin-top">资产信息</el-divider> -->
      <el-row class="padding" style="padding-bottom:0">
        <el-form-item label="分类编号" prop="categoryCode">
          <el-input v-model="dataInfo.categoryCode" autocomplete="off" placeholder="输入分类编号"></el-input>
        </el-form-item>
        <el-form-item label="分类名称" prop="categoryName">
          <el-input v-model="dataInfo.categoryName" autocomplete="off" placeholder="请输入分类名称"></el-input>
        </el-form-item>
        <el-form-item label="上级分类" prop="parentCode">
          <el-cascader v-model="dataInfo.parentCodeArr" :props="categoryProps" :options="categoryList"></el-cascader>
        </el-form-item>
        <el-form-item label="排序" prop="sort">
          <el-input v-model="dataInfo.sort" autocomplete="off"></el-input>
        </el-form-item>
        <el-form-item label="备注" prop="remark">
          <el-input v-model="dataInfo.remark" autocomplete="off" placeholder="备注信息"></el-input>
        </el-form-item>
      </el-row>
    </el-form>
    <div slot="footer" class="dialog-footer">
      <el-button @click="_cancle">取 消</el-button>
      <el-button type="primary" @click="save">保存</el-button>
    </div>
  </el-dialog>
</template>

<script>
export default {
  data() {
    return {
      title: '', // 内置参数,误删
      visible: true, // 内置参数,误删
      dataInfo: null,
      categoryList: null,
      categoryProps: {
        checkStrictly: true,
        expandTrigger: 'hover',
        value: 'categoryCode',
        label: 'categoryName'
      },
      dataRules: {
        categoryCode: [{ required: true, trigger: 'blur', message: '分类编号不能为空' }],
        sort: [{ required: true, trigger: 'blur', message: '排序不能为空' }],
        categoryName: [{ required: true, trigger: 'blur', message: '分类名称不能为空' }]
      }
    }
  },
  mounted() {
    this.getCategoryList()
  },
  methods: {
    _closed() {
      // 底层内置方法 误删
      this.onclosed()
    },
    _cancle() {
      // 底层内置方法 误删
      this.visible = false
      this.cancle && this.cancle()
    },
    getCategoryList() {
      this.$lgh.get('api/enterprise/easset/category/tree', ['enterpriseCode'], false).then(res => {
        res.unshift({
          categoryCode: '',
          categoryName: '[无上级]'
        })
        this.categoryList = res
      })
    },
    save() {
      this.$refs.dataInfo.validate(async valid => {
        if (valid) {
          await this.$lgh.post('api/enterprise/easset/category/addoredit', this.dataInfo)
          this.visible = false
          this.onsuccess && this.onsuccess(this.dataInfo)
        }
      })
    }
  }
}
</script>

<style></style>
